.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.reactflow-wrapper .react-flow__pane {
  cursor: url('data:image/svg+xml;utf8,<svg width="32px" height="32px" viewBox="0 0 0.64 0.64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="%23444" d="M0.54 0.096c-0.016 -0.016 -0.04 -0.02 -0.06 -0.012 0 -0.012 -0.004 -0.024 -0.016 -0.036 -0.008 -0.008 -0.024 -0.016 -0.044 -0.016 -0.012 0 -0.02 0.004 -0.028 0.004 0 -0.008 -0.004 -0.012 -0.008 -0.02 -0.02 -0.024 -0.06 -0.024 -0.08 0 -0.008 0.008 -0.016 0.016 -0.016 0.024C0.28 0.04 0.272 0.036 0.264 0.036c-0.02 0 -0.032 0.008 -0.044 0.02C0.2 0.076 0.2 0.108 0.2 0.108v0.152c-0.012 -0.012 -0.032 -0.032 -0.06 -0.032 -0.008 0 -0.02 0.004 -0.028 0.008 -0.016 0.008 -0.024 0.02 -0.028 0.036 -0.012 0.04 0.024 0.096 0.024 0.1 0.004 0.004 0.048 0.108 0.088 0.152C0.236 0.572 0.28 0.6 0.392 0.6c0.116 0 0.168 -0.064 0.168 -0.204V0.176c0 -0.004 0.004 -0.052 -0.02 -0.08M0.32 0.08c0 -0.012 -0.004 -0.04 0.02 -0.04 0.02 0 0.02 0.02 0.02 0.04v0.16c0 0.012 0.008 0.02 0.02 0.02s0.02 -0.008 0.02 -0.02V0.088s0 -0.016 0.02 -0.016c0.024 0 0.02 0.036 0.02 0.036V0.24c0 0.012 0.008 0.02 0.02 0.02s0.02 -0.008 0.02 -0.02V0.144c0 -0.004 0 -0.024 0.02 -0.024s0.02 0.04 0.02 0.04v0.236c0 0.136 -0.052 0.164 -0.128 0.164 -0.096 0 -0.132 -0.02 -0.164 -0.064 -0.036 -0.04 -0.084 -0.144 -0.084 -0.148 -0.012 -0.012 -0.028 -0.048 -0.024 -0.064 0 -0.004 0.004 -0.008 0.008 -0.012 0.004 0 0.008 -0.004 0.008 -0.004 0.016 0 0.032 0.02 0.036 0.028l0.024 0.036c0.004 0.008 0.016 0.012 0.024 0.008 0.016 0 0.02 -0.008 0.02 -0.016V0.116c0 -0.016 0 -0.04 0.02 -0.04 0.016 0 0.02 0.012 0.02 0.032V0.24c0 0.012 0.008 0.02 0.02 0.02S0.32 0.252 0.32 0.24z"/></svg>')
      16 16,
    grab;
}

.reactflow-wrapper .react-flow__pane.dragging,
.reactflow-wrapper .react-flow__node.dragging {
  cursor: url('data:image/svg+xml;utf8,<svg width="32px" height="32px" viewBox="0 0 0.64 0.64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="%23444" d="M0.504 0.16H0.48c0 -0.008 -0.008 -0.024 -0.016 -0.032s-0.02 -0.016 -0.044 -0.016c-0.008 0 -0.016 0 -0.024 0.004 -0.004 -0.008 -0.008 -0.012 -0.012 -0.02 -0.008 -0.008 -0.02 -0.016 -0.044 -0.016 -0.032 0 -0.048 0.02 -0.056 0.04 -0.004 0 -0.012 -0.004 -0.02 -0.004 -0.02 0 -0.032 0.008 -0.044 0.016C0.2 0.156 0.2 0.188 0.2 0.192v0.016c-0.024 0 -0.044 0.008 -0.056 0.02C0.12 0.256 0.12 0.292 0.12 0.34v0.028c0 0.056 0.028 0.084 0.056 0.112l0.012 0.016C0.24 0.544 0.288 0.56 0.392 0.56c0.116 0 0.168 -0.064 0.168 -0.204V0.256c0 -0.028 -0.008 -0.084 -0.056 -0.096m-0.084 -0.008c0.016 0 0.02 0.016 0.02 0.024v0.032c0 0.012 0.008 0.02 0.016 0.02 0.012 0 0.02 -0.004 0.02 -0.016 0 0 0 -0.016 0.016 -0.012 0.024 0.008 0.028 0.044 0.028 0.052v0.104c0 0.136 -0.052 0.164 -0.128 0.164 -0.096 0 -0.132 -0.012 -0.172 -0.052l-0.016 -0.016C0.176 0.424 0.16 0.408 0.16 0.368v-0.024c0 -0.04 0 -0.072 0.012 -0.084 0.004 -0.008 0.016 -0.012 0.028 -0.012V0.28l-0.012 0.048c0 0.004 0 0.004 0.004 0.004 0.004 0.004 0.008 0 0.008 0l0.04 -0.048V0.2c0 -0.004 0 -0.024 0.008 -0.032 0.004 -0.004 0.008 -0.008 0.016 -0.008 0.012 0 0.016 0.008 0.016 0.016v0.016c0 0.008 0.008 0.02 0.02 0.02S0.32 0.2 0.32 0.192V0.14c0 -0.004 0 -0.02 0.02 -0.02 0.012 0 0.02 0.008 0.02 0.02v0.048c0 0.012 0.008 0.024 0.02 0.024s0.02 -0.012 0.02 -0.02v-0.02c0 -0.012 0.008 -0.02 0.02 -0.02"/></svg>') 16 16, grabbing;
}

.reactflow-wrapper .react-flow__node,
.reactflow-wrapper .react-flow__pane .react-flow__edge,
.reactflow-wrapper .react-flow__pane .react-flow__edges {
  cursor: url('data:image/svg+xml;utf8,<svg width="32px" height="32px" viewBox="0 0 0.64 0.64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="%23444" d="M0.54 0.096c-0.016 -0.016 -0.04 -0.02 -0.06 -0.012 0 -0.012 -0.004 -0.024 -0.016 -0.036 -0.008 -0.008 -0.024 -0.016 -0.044 -0.016 -0.012 0 -0.02 0.004 -0.028 0.004 0 -0.008 -0.004 -0.012 -0.008 -0.02 -0.02 -0.024 -0.06 -0.024 -0.08 0 -0.008 0.008 -0.016 0.016 -0.016 0.024C0.28 0.04 0.272 0.036 0.264 0.036c-0.02 0 -0.032 0.008 -0.044 0.02C0.2 0.076 0.2 0.108 0.2 0.108v0.152c-0.012 -0.012 -0.032 -0.032 -0.06 -0.032 -0.008 0 -0.02 0.004 -0.028 0.008 -0.016 0.008 -0.024 0.02 -0.028 0.036 -0.012 0.04 0.024 0.096 0.024 0.1 0.004 0.004 0.048 0.108 0.088 0.152C0.236 0.572 0.28 0.6 0.392 0.6c0.116 0 0.168 -0.064 0.168 -0.204V0.176c0 -0.004 0.004 -0.052 -0.02 -0.08M0.32 0.08c0 -0.012 -0.004 -0.04 0.02 -0.04 0.02 0 0.02 0.02 0.02 0.04v0.16c0 0.012 0.008 0.02 0.02 0.02s0.02 -0.008 0.02 -0.02V0.088s0 -0.016 0.02 -0.016c0.024 0 0.02 0.036 0.02 0.036V0.24c0 0.012 0.008 0.02 0.02 0.02s0.02 -0.008 0.02 -0.02V0.144c0 -0.004 0 -0.024 0.02 -0.024s0.02 0.04 0.02 0.04v0.236c0 0.136 -0.052 0.164 -0.128 0.164 -0.096 0 -0.132 -0.02 -0.164 -0.064 -0.036 -0.04 -0.084 -0.144 -0.084 -0.148 -0.012 -0.012 -0.028 -0.048 -0.024 -0.064 0 -0.004 0.004 -0.008 0.008 -0.012 0.004 0 0.008 -0.004 0.008 -0.004 0.016 0 0.032 0.02 0.036 0.028l0.024 0.036c0.004 0.008 0.016 0.012 0.024 0.008 0.016 0 0.02 -0.008 0.02 -0.016V0.116c0 -0.016 0 -0.04 0.02 -0.04 0.016 0 0.02 0.012 0.02 0.032V0.24c0 0.012 0.008 0.02 0.02 0.02S0.32 0.252 0.32 0.24z"/></svg>')
  16 16,
       grab;
}

.reactflow-wrapper .react-flow__handle {
  width: 8px;
  height: 8px;
}

.reactflow-wrapper .react-flow__handle.connectionindicator {
  cursor: url('data:image/svg+xml;utf8,<svg width="32px" height="32px" viewBox="0 0 1.28 1.28" version="1.1" xmlns="http://www.w3.org/2000/svg"><title>crosshair</title><path fill="%23444" d="M1.2 0.59h-0.113c-0.024 -0.209 -0.188 -0.373 -0.395 -0.397l-0.002 0V0.08a0.05 0.05 0 0 0 -0.1 0v0.113c-0.209 0.024 -0.373 0.188 -0.397 0.395l0 0.002H0.08a0.05 0.05 0 0 0 0 0.1h0.113c0.024 0.209 0.188 0.373 0.395 0.397l0.002 0v0.113a0.05 0.05 0 0 0 0.1 0v-0.113c0.209 -0.024 0.373 -0.188 0.397 -0.395l0 -0.002H1.2a0.05 0.05 0 0 0 0 -0.1m-0.51 0.395V0.88a0.05 0.05 0 0 0 -0.1 0v0.105c-0.153 -0.023 -0.272 -0.142 -0.295 -0.293l0 -0.002H0.4a0.05 0.05 0 0 0 0 -0.1H0.295c0.023 -0.153 0.142 -0.272 0.293 -0.295l0.002 0V0.4a0.05 0.05 0 0 0 0.1 0V0.295c0.153 0.023 0.272 0.142 0.295 0.293l0 0.002H0.88a0.05 0.05 0 0 0 0 0.1h0.105c-0.023 0.153 -0.142 0.272 -0.293 0.295z"/></svg>')
      16 16,
    crosshair;
}

.reactflow-wrapper .react-flow__node-default.selectable.selected {
  background-color: #f9f9f9;
}

.reactflow-wrapper .react-flow__handle-connecting {
  background: #ff6060;
}

.reactflow-wrapper .react-flow__handle-valid {
  background: #08b36b;
}
